//require bootstrap, bootstrap-select, jquery

$(document).ready(function(){

 




    if(typeof(config) != 'undefined'){

        //Endinscription handle
        $('.btn-end-inscription').click(function(){
            $('#utilisateur-form').append('<input type="hidden" name="terminateProcess" value="true"/>');
            $('#utilisateur-form').submit();
            return false;
        })

        //Autoscroll if participant
        if(config.isStep2){
            $('html, body').animate({
                scrollTop: ($("form input").first().offset().top -150)
            }, 0);
        }

        // If errors show modal
        if(config.formIsInError){
            forceAddAccModalToShow();
        }

        //Ui treatment
        //treatment();

        //Autocomplete address field by geolocation
        if(!config.isStep2){
            if(navigator.geolocation)
            navigator.geolocation.getCurrentPosition(treatAutocompleteAddress);
        }

        //Also called in the AJAX return event
        domFormTreatment();

        //Treatment on forms element
        treatAjaxActions();
    }
});
var currentSubEvents = [];

function domFormTreatment(){
  //Transform select to ul li
  // $('select').selectpicker();// mise en commentaire pour permettre la validation HTML
  // (AAAUTRE) >>>>>>>>>>>>
  // Gestion des champs "autre" (AAAUTRE) necessitant un champ complementaire de saisie textuelle

  $('select').change(function() {
    if (this.value == 'AAAUTRE') {
      showBoiteComplementaire(this);
    }
    else {
      hideBoiteComplementaire(this);
    }
  });
  // <<<<<<<<<< (AAAUTRE)
    //Specific sousevenement

    //Get all subevents
    currentSubEvents = [];
    $('[data-domtype]').each(function(){
        currentSubEvents.push({
            elt : this,
            stock : $(this).attr('data-stock'),
            nbParticipants : $(this).attr('data-nbParticipants'),
            debut : $(this).attr('data-debut'),
            fin : $(this).attr('data-fin')
        });
    });

    isSelectedUpdate();

    //Checkbox handling
    $('tr[data-domtype] input').each(function(){
        var cElt = this;
        $(cElt).change(function(){
            removeAlerts(cElt); // ?
            isSelectedUpdate();
            checkStocks();
            checkPeriods();
        });
    });
    //select handling
    $('option[data-domtype]').parent().each(function(){
        var cElt = this;
        $(cElt).change(function(){
            removeAlerts(cElt); // ?
            isSelectedUpdate();
            checkStocks();
            checkPeriods();
        });
    });
}
function isSelectedUpdate(){
    for(var i in currentSubEvents){
        var cInput = currentSubEvents[i].elt;
        var isCbox = $(cInput).is('tr');
        var isOption = $(cInput).is('option');
        var isSelected = false;
        if(isCbox){
            isSelected = $(cInput).find('[type=radio]:checked').val() == 1;
        }
        if(isOption){
            isSelected = $(cInput).parent().val() == $(cInput).val();
        }
        currentSubEvents[i].isSelected = isSelected;
    }
}
function checkStocks(){
    for(var i in currentSubEvents){
        var cInput = currentSubEvents[i].elt;
        var cEvent = currentSubEvents[i];
        if(cEvent.isSelected && cEvent.stock > 0){
            if(cEvent.stock - (cEvent.nbParticipants+1) <=0){
                putAnAlert(cInput, null, "Il n'y a plus de place disponible pour un de vos choix", 'stock-msg');
            }
        }
    }
}
function checkPeriods(){
    for(var i in currentSubEvents){
        var cInput = currentSubEvents[i].elt;
        var cEvent = currentSubEvents[i];
        if(cEvent.isSelected){
            for(var j in currentSubEvents){
                if(j != i){
                    var otherEvent = currentSubEvents[j];
                    if(otherEvent.isSelected){
                        if(isPeriodOverlapping(cEvent, otherEvent)){
                            putAnAlert(cInput, this, 'Un de vos choix se déroule durant un autre sous événement', 'date-msg');
                        }
                    }
                }
            }
        }
    }
}
function putAnAlert(elt, secondElt, msg, type){
    //glyphicon glyphicon-calendar
    var $msgContainer = $('.sousEvenement-errormsg');

    if(type == 'stock-msg'){
        $(elt).parent().parent().addClass('stock-warning');
         msg = '<i class="glyphicon glyphicon-alert"></i> '+msg;
    }else if (type == 'date-msg') {
        $(elt).parent().parent().addClass('date-warning');
        $(secondElt).parent().addClass('date-warning');
         msg = '<i class="glyphicon glyphicon-calendar"></i> '+msg;
    }
    if($msgContainer.find('.'+type).length > 0){
    }else{
        $msgContainer.append($('<div class="alert alert-warning '+type+'">'+msg+'</div>'));
    }
}
function removeAlerts(cElt){
    $('.sousEvenement-errormsg').html('');
    $('.stock-warning, .date-warning').removeClass('stock-warning').removeClass('date-warning');
}
function hasAPeriodOverlapping(testedElt, currentElt){
    if($(currentElt).attr('id') != $(testedElt).attr('id')){
        var period1={
            'debut' : $(testedElt).attr('data-debut'),
            'fin' : $(testedElt).attr('data-fin')
        };
        var period2={
            'debut' : $(currentElt).attr('data-debut'),
            'fin' : $(currentElt).attr('data-fin')
        };
        if(isPeriodOverlapping(period1, period2)){
            return true;
        }else{
            return false;
        }
    }
}
function isPeriodOverlapping(period1, period2){
    var debut = period1.debut;
    var fin = period1.fin;
    var debutC = period2.debut;
    var finC = period2.fin;
    if(debutC && finC && debut && fin) {
        var debutDate= new Date(debut);
        var finDate = new Date(fin);
        var debutDateC = new Date(debutC);
        var finDateC = new Date(finC);
        if((debutDateC < debutDate && finDateC < debutDate) || (debutDateC > finDate && finDateC > finDate)){
            return false;
        }else{
            return true;
        }
    }
}

function treatment(){
    $('.form-group input[type=text]').each(function(){
        subTreatment(this);
    });
    $('.form-group input[type=email]').each(function(){
        subTreatment(this);
    });

    $('input').each(function(){
        if($(this).val() != ''){
            $(this).parent().addClass('input-filled');
        }
        else{
            $(this).parent().removeClass('input-filled');
        }
    })
    $('input').blur(function(){
        if($(this).val() != ''){
            $(this).parent().addClass('input-filled');
        }
        else{
            $(this).parent().removeClass('input-filled');
        }
    });
}
function subTreatment(elt){
    $(elt).parent().addClass('input');
    $(elt).parent().append($(elt).parent().find('label'))
    $(elt).parent().find('label').addClass('event-style-label');
    $(elt).parent().find('input').addClass('event-style');
    $(elt).parent().find('label').wrapInner("<span class='event-style-label-content'></span>");
    $(elt).parent().find('label').removeClass('control-label');
    $(elt).parent().find('input').removeClass('form-control');
    $(elt).parent().find('input').removeAttr('placeholder');
}
function treatAutocompleteAddress(position){
    var infopos = "Position déterminée :\n";
    infopos += "Latitude : "+position.coords.latitude +"\n";
    infopos += "Longitude: "+position.coords.longitude+"\n";
    infopos += "Altitude : "+position.coords.altitude +"\n";

    $.getJSON('http://nominatim.openstreetmap.org/reverse?format=json&lat='+position.coords.latitude+'&lon='+position.coords.longitude, function(data) {
        // console.log(data)
        // $('#utilisateur_adresse').val(data.address.pedestrian);
        $('#utilisateur_ville').val(data.address.village || data.address.city || data.address.town);
        var codePost = data.address.postcode.split(';');
        $('#utilisateur_codePostal').val(codePost[0]);
    });

    // var $input = $('#utilisateur_adresse');
    // $input.attr('autocomplete', 'off');
    // var items = {};
    // $input.typeahead({
    //     source:function ( query, process ){
    //         $.getJSON('http://nominatim.openstreetmap.org/search?format=json&limit=5&addressdetails=1&&country=fr&street=' + query, function(data) {
    //             var itemLabels = [];
    //             $.each(data, function(key, val) {
    //                 var value = val.display_name
    //                 items[value] = val;
    //                 itemLabels.push(value);
    //             });
    //             process( itemLabels );
    //         });
    //     }, updater: function (item) {
    //         //save the id value into the hidden field
    //         // $( "#userId" ).val( users[ item ] );
    //         var obj = items[item];
    //         console.log(items[item]);
    //         $('#utilisateur_ville').val(obj.address.village || obj.address.city || obj.address.town);
    //         $('#utilisateur_codePostal').val(obj.address.postcode);
    //
    //         item = obj.address.road;
    //         if(obj.address.house_number)
    //         item = obj.address.house_number+ ', ' +obj.address.road;
    //         //return the string you want to go into the textbox (e.g. name)
    //         return item;
    //     },
    //     autoSelect: true
    // });
}
function treatAjaxActions(){



    $('.preinscriptionForm').validator().on('submit', function (e) {
        if (e.isDefaultPrevented()) {
            // handle the invalid form...
        } else {
            var emailChecker = $("input[name=email-checker]").val();
            var slug = config.slugEvenement;
            $.ajax({
                url: evenementAjaxPathes.emailCheckAjax,
                type: 'POST',
                dataType: 'html',
                data: {
                    'email-checker': emailChecker,
                    'slug-evenement': slug,
                    'is-partenaire' : config.isPartenaire,
                    'is-presse' : config.isPresse
                }
            })
            .done(function(data) {
                if(data == 404){
                    //Show empty form
                    $('#utilisateur_email').val(emailChecker);
                    $('.inscription.hide').removeClass('hide');
                    $('.preinscription').addClass('hide');
                }else if(data == 301){
                    //Redirect to inscriptionReussie
                    document.location.href = evenementAjaxPathes.inscriptionDejaEffectuee;
                }else{
                    $('.inscription-formContainer').html(data);
                    $('.inscription.hide').removeClass('hide');
                    $('.preinscription').addClass('hide');
                    domFormTreatment();
                }
            })
            .fail(function() {
                message("Une erreur s'est produite.");
            })
            .always(function() {
            });
            return false;
        }
    })
    // $('.preinscriptionForm').submit(function(){
    //
    // });
    //Editing an element
    $('.btn-edit-evenement').click(function(){
        var inscriptionId = $(this).attr('data-inscription');
        $.ajax({
            url: evenementAjaxPathes.inscriptionEditAjax,
            type: 'POST',
            dataType: 'html',
            data: {'inscriptionId': inscriptionId}
        })
        .done(function(data) {
            $('.modal-edit').modal('show');
            $('.modal-edit .modal-body').html(data).find('.btn-bar').hide();
        })
        .fail(function() {
            message("Une erreur s'est produite.");
        })
        .always(function() {
        });
        return false;
    });
    $('.modal-edit .btn-confirm-edit').click(function(){
        $.ajax({
            url: evenementAjaxPathes.inscriptionEditAjax,
            type: 'POST',
            dataType: 'html',
            data: $('.modal-edit .modal-body form').serialize()
        })
        .done(function(data) {
            $('.modal-edit').modal('hide');
            var url = $('#utilisateur-form').attr('action');
            document.location.href = url;
        })
        .fail(function() {
            message("Une erreur s'est produite.");
        })
        .always(function() {
        });
        return false;
    });


    //Removing an element
    var $currentElement = null;
    $('.btn-remove-evenement').click(function(){
        var inscriptionId = $(this).attr('data-inscription');
        var isPrincipal = $(this).attr('data-isPrincipal') == 'true';

        $currentElement = $(this);
        $('.modal-confirm-deletion').attr('data-inscription', inscriptionId)
        $('.modal-confirm-deletion').attr('data-isPrincipal', isPrincipal)
        $('.modal-confirm-deletion').modal('show');
        return false;
    });
    $('.modal-confirm-deletion .btn-confirm-deletion').click(function(){
        var inscriptionId = $currentElement.attr('data-inscription');
        var isPrincipal = $currentElement.attr('data-isPrincipal') == 'true';
        $.ajax({
            url: evenementAjaxPathes.inscriptionRemoveAjax,
            type: 'POST',
            dataType: 'json',
            data: {'inscriptionId': inscriptionId, 'isPrincipal': isPrincipal}
        })
        .done(function() {
            //Removing in UI.
            if(isPrincipal) window.location.href = evenementAjaxPathes.inscription;
            $currentElement.parents('.panel').first().parent().remove();
        })
        .fail(function() {
            message("Une erreur s'est produite.");
        })
        .always(function() {
            $('.modal-confirm-deletion').modal('hide');
            $currentElement = null;
        });
        return false;
    });

}
function message(message){
    $('.alert-page').removeClass('hide')
    $('.alert-page').find('p').html(message);
}
function forceAddAccModalToShow(){
    $(document).ready(function(){
        $('.modal-add-accompagnant').modal('show');
    });
}

$(document).ready(function(){
    if(typeof(atix_badge_url) != 'undefined'){
        atix_badge_url = atix_badge_url.replace("amp;", "");
        window.location.href = atix_badge_url;
    }
});



// (AAAUTRE) >>>>>>>>>>>>
function showBoiteComplementaire(element){
  var new_id = 'formextra_'+element.id+'_autre';
  $("#"+element.id)
  .parent().append( "<p id='p_"+new_id+"'><input type='text' id="+new_id+" name="+new_id+" value='' placeholder='Précisez' required class='form-control'></p>" )
  ;
}

function hideBoiteComplementaire(element){
  var new_id = 'formextra_'+element.id+'_autre';
  $( "#p_"+new_id ).remove();
}
// <<<<<<<<<<<< (AAAUTRE)
