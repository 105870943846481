var $collectionHolder;

// setup an "add a tag" link
var $addParticipantLink = $('<a href="#" class="add_particpant_link btn-lg btn-link btn"> <span class="glyphicon glyphicon-plus"></span> Inviter une personne</a>');
var $newLinkLi = $('<li class="new_participant"></li>').append($addParticipantLink);

jQuery(document).ready(function() {
    // Get the ul that holds the collection of tags
    $collectionHolder = $('ul.participants');

    // add the "add a tag" anchor and li to the tags ul
    $collectionHolder.append($newLinkLi);

    $collectionHolder.find('li.item_participant').each(function() {
        addParticipantFormDeleteLink($(this));
    });

    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    // $collectionHolder.data('index', $collectionHolder.find(':input').length);
    $collectionHolder.data('index', $collectionHolder.find('.item_participant').length + 1);

    $addParticipantLink.on('click', function(e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();

        // add a new tag form (see next code block)
        addParticipantForm($collectionHolder, $newLinkLi);
    });
});



function addParticipantForm($collectionHolder, $newLinkLi) {
    // Get the data-prototype explained earlier
    var prototype = $collectionHolder.data('prototype');

    // get the new index
    var index = $collectionHolder.data('index');

    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    var newForm = prototype.replace(/__name__/g, index);

    // increase the index with one for the next item
    $collectionHolder.data('index', index + 1);
    
    // Display the form in the page in an li, before the "Add a tag" link li
    var $newFormLi = $('<li class="item_participant"></li>').append(newForm);
    addParticipantFormDeleteLink($newFormLi);
    $newLinkLi.before($newFormLi);
    
    
    gestionChampsAutre("item_participant");
    
}

function gestionChampsAutre(liConcerne) {
    $("."+liConcerne+" .precision").parent().hide();
    
    $("."+liConcerne+" .liste").each(function() {
        if($(this).val().toLowerCase().indexOf("autre") !== -1) {
             $(this).parent().next().find(".precision").parent().show();
             $(this).parent().next().find(".precision").prop("required", false);
        } else {
            $(this).parent().next().find(".precision").val("");
            $(this).parent().next().find(".precision").parent().hide();
        }
    });
    
    $("."+liConcerne+" .liste").change(function(){
        if($(this).val().toLowerCase().indexOf("autre") !== -1) {
             $(this).parent().next().find(".precision").parent().show();
        } else {
            $(this).parent().next().find(".precision").val("");
            $(this).parent().next().find(".precision").prop("required", false);
            $(this).parent().next().find(".precision").parent().hide();
        }
    });
}


function addParticipantFormDeleteLink($participantFormLi) {
    var $removeFormA = $('<a href="#" class="btn-danger btn"> <span class="glyphicon glyphicon-trash"></span > Supprimer cette inscription</a>');
    $participantFormLi.append($removeFormA);

    $removeFormA.on('click', function(e) {

        if(confirm('êtes vous certain de supprimmer cette inscription?')){
            // prevent the link from creating a "#" on the URL
            e.preventDefault();

            // remove the li for the tag form
            $participantFormLi.remove();
        }

    });
}