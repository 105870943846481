function constructTable(url){
    $('.testPlugin').contactHandler({
        'urlContacts': url,
        fields : [
            {name:'nom',label:'Nom'},
            {name:'prenom',label:'Prénom'}
        ],
        buttons:[
            {
                label: 'Button 1',
                type: 'multiSelect',
                field: 'tags'
            },
            {
                label: 'Editer',
                type: 'action',
                onclick: function(){
                    console.log('toto')
                }
            }
        ]
    });
}

// jQuery Plugin contactHandler
// Handling Calixx contacts from Pleedi
// version 1.0, 29/07/2016
// by Julien Froidefond



(function($) {

    $.contactHandler = function(element, options) {

        // plugin's default options
        // this is private property and is  accessible only from inside the plugin
        var defaults = {
            urlContacts: null,
            fields : [
                {name:'nom',label:'Nom'},
                {name:'prenom',label:'Prénom'}
            ],
            buttons:[],
            // if your plugin is event-driven, you may provide callback capabilities
            // for its events. execute these functions before or after events of your 
            // plugin, so that users may customize those particular events without 
            // changing the plugin's code
            onTableConstructed: function() {}

        }

        var plugin = this;

        plugin.settings = {}

        var $element = $(element), element = element;

        var contactOrc = new ContactOrchestrator(plugin, $element);

        plugin.init = function() {

            // the plugin's final properties are the merged default and 
            // user-provided options (if any)
            plugin.settings = $.extend({}, defaults, options);

            contactOrc.init();

        }



        plugin.init();

    }

    // add the plugin to the jQuery.fn object
    $.fn.contactHandler = function(options) {

        // iterate through the DOM elements we are attaching the plugin to
        return this.each(function() {

            // if plugin has not already been attached to the element
            if (undefined == $(this).data('contactHandler')) {

                // create a new instance of the plugin
                // pass the DOM element and the user-provided options as arguments
                var plugin = new $.contactHandler(this, options);

                // in the jQuery version of the element
                // store a reference to the plugin object
                // you can later access the plugin and its methods and properties like
                // element.data('contactHandler').publicMethod(arg1, arg2, ... argn) or
                // element.data('contactHandler').settings.propertyName
                $(this).data('contactHandler', plugin);

            }

        });

    }

})(jQuery);


var ContactOrchestrator = function(plugin, $element){
    var cObj = this;
    cObj.plugin = plugin;
    cObj.element = $element;

    cObj.init = function(){
        domConstruction();
        buttonsConstruction();
        initDynatable();
    }

    var domConstruction = function(){
        //table
        var btns = cObj.plugin.settings.buttons;
        var hasActions = false;
        for(var i in btns){
            if(btns[i].type == 'action'){
                hasActions = true;
                break;
            }
        }
        var domString=
            '<table class="contactTable table table-stripped">\
                <thead>';
        for(var i in cObj.plugin.settings.fields){
            var field = cObj.plugin.settings.fields[i];
            domString += '<th data-dynatable-column="'+field.name+'">'+field.label+'</th>';
        }
        if(hasActions){
            domString += '<th>Actions</th>';
        }
        domString+=
            '</thead>\
            <tbody>\
            </tbody>\
        </table>';
        cObj.element.append($(domString));
        //buttons

    }

    var initDynatable = function(){
        cObj.plugin.dynatable = $element.find('.contactTable').dynatable({
            dataset: {
                ajax: true,
                ajaxUrl: plugin.settings.urlContacts,
                ajaxOnLoad: true,
                records: []
            },
            writers: {
                /*_cellWriter: function(field, record){
                    /!*console.log(field);
                    console.log(record);*!/

                    if(field.id == 'actions'){
                        return '';
                    }else{
                        /!*console.log(record[field.id])*!/
                        return record[field.id];
                    }
                }*/
            }
        }).data('dynatable');

    }
    var buttonsConstruction = function(){
        $('.testClick').click(function(){
            dynatable.queries.add('year','2006');
            dynatable.process();
        });
    }
}


